import { mapActions, mapAction, mapGetter } from 'CommonUtils/store/state.js';
import StockSchema from 'CommonUtils/schemas/stock';

const useAsyncStock = (products) => {
  const productsChecked = ref([]);
  const stockResponses = reactive({});
  const { getStock } = mapActions('page', { getStock: 'getStock' });
  const addGiftsWithStock = mapAction('page', 'addGiftsWithStock');
  const giftsWithStock = mapGetter('page/getGiftsWithStock');
  const stockProducts = ref([]);
  const mustHidePromotions = inject('hidePromotions');

  const getStockProducts = () => {
    if (!productsChecked.value?.length) return products.value;

    return products.value?.map((product) => {
      const stock = stockResponses.products?.[product.id] ?? {};
      const redirectToPDP = stock.redirectToPDP || product._is_collection || stock.status?.toUpperCase() === StockSchema.STATUS.FORM;
      const { previewDate } = stock;
      const hasProductGifts = StockSchema.productHasPromotionalProducts(stockResponses, product.id);
      let productPromos = [];
      let principal_promo = [];
      let principal_gif = [];
      let updatedMyColors = product._my_colors;

      if (hasProductGifts) {
        productPromos = stockResponses.promos[product.id].filter((promo) => {
          if(promo.type === 'gift'){
            return promo.target_references?.some(giftReference => giftsWithStock.value.includes(giftReference));
          } else {
            return true;
          }
        });
        principal_promo = productPromos;
      } else {
        principal_promo = stockResponses.promos?.[product.id] ?? [];
      }
      updatedMyColors = StockSchema.updateVariantSpecificPromos(product, principal_promo);

      if (stockResponses.principalGifs?.[product.id]?.is_product_promo) {
        if (productPromos.find(promo => promo.id === stockResponses.principalGifs?.[product.id].promo_id)) {
          principal_gif = stockResponses.principalGifs[product.id];
        }
      } else {
        principal_gif = stockResponses.principalGifs?.[product.id] ?? {};
      }

      return {
        ...product,
        previewDate,
        principal_gif,
        principal_promo,
        redirectToPDP,
        stock,
        updatedMyColors,
      };
    });
  };

  // NOTE: onMounted because we don't want to block ssr
  onMounted(() => (watchEffect(getProductsStock)));

  const getProductsStock = async () => {
    const productsToCheck = getProductsToCheck();
    if (!productsToCheck?.length) return;
    
    addProductsToCheck(productsToCheck);
    const payload = {
      ids: productsToCheck,
      type: 'product'
    };
    const response = await getStock(payload);
    handleStockResponse(response, payload.ids);
  };

  const addProductsToCheck = (productsToCheck) => {
    productsChecked.value = [...productsChecked.value, ...productsToCheck];
  };

  const getProductsToCheck = () => {
    const productIds = products.value?.reduce((products, product) => {
      const shouldCheck = shouldCheckProductStock(product);
      if (shouldCheck) products.push(product.id);
      return products;
    }, []) ?? [];

    return productIds;
  };

  const shouldCheckProductStock = ({ _my_colors, id }) => {
    if (!id) return;

    const hasBeenCalledStock = productsChecked.value.find((productId) => productId === id);
    if (hasBeenCalledStock) return;

    const shouldCheckPredicate = ({ add_to_cart }) => StockSchema.shouldCheckStock(add_to_cart);
    const hasAvailableSkus = _my_colors?.some(shouldCheckPredicate);
    return hasAvailableSkus;
  };

  const handleStockResponse = (response, ids) => {
    const data = !response || !response.data ? StockSchema.getDefaultStockResponse(ids) : response.data;
    if(!response || !response.data) {
      console.error('[AsyncStock] Error fetching stock. ', response);
    }
    const { products, principalGifs, promos } = data;
    stockResponses.products = { ...stockResponses.products, ...products };
    stockResponses.principalGifs = { ...stockResponses.principalGifs, ...principalGifs };
    stockResponses.promos = { ...stockResponses.promos, ...promos };
    stockProducts.value = getStockProducts();
    !mustHidePromotions && getGiftsStock(response)
  };

  const getGiftsStock = async (productsStock) => {
    const giftReferences = StockSchema.getAllGiftReferencesFromStock(productsStock.data)
      .filter((reference) => {
        return !productsChecked.value.includes(reference) && !giftsWithStock.value.includes(reference);
      });
    addProductsToCheck(giftReferences);
    if (giftReferences.length) {
      getStock({ ids: giftReferences, type: 'gifts' }).then((giftsStock) => handleGiftsStock(giftsStock));;
    }
  };

  const handleGiftsStock = async (giftsStock) => {
    if (!giftsStock?.success) return console.error('[AsyncStock] Error fetching gifts stock', giftsStock);
    await addGiftsWithStock(giftsStock.data.ADD);
    stockProducts.value = getStockProducts();
  }

  return { stockProducts };
}
export default useAsyncStock;